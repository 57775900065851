<template>
  <div class="row m-auto col-md-12">
    <div class="col-md-7 ml-auto">
      <div class="row">
        <div class="col-md-6 m-auto">
          <button
            type="button"
            class="btn btn_secondary"
            @click.prevent="$emit('closeModal')"
          >
            Cancelar
          </button>
        </div>
        <div class="col-md-6 m-auto">
          <button
            type="button"
            :disabled="disabled"
            @click="$emit('submitform')"
            class="btn btn_default"
          >
            <!-- <div class="spinner" v-if="disabled">
              <half-circle-spinner
                :animation-duration="1000"
                :size="25"
                color="#fff"
              />
            </div> -->
            <!-- <span v-else> -->
            {{ nameButton }}
            <!-- </span> -->
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";
export default {
  props: ["nameButton", "disabled"],
  components: {
    HalfCircleSpinner
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
